import React from 'react'
import { Link } from "gatsby"
import * as style from "../static/styles/footer.module.scss"

const Footer = () => {
    return (
        <div className={style.footerRoot}>
            <div>
                <Link to="/contact">Contact</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
                <p className={style.footerCopyright}>©{new Date().getFullYear()} {` `} <Link to="/"> Gamersmancave NL</Link> All rights reserved.</p>
        </div>
    )
}

export default Footer